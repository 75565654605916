import React, { useState } from 'react';
import { BrowserProvider, Contract, parseEther, Interface } from 'ethers';
import NFTModal from './NFTModal';
import Notification from './Notification';
import ETCPOW_ABI from '../contracts/ETCPOW-ABI.json';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';
import DAO2_ABI from '../contracts/ETCMC-DAO2-ABI.json';
import DAO1_ABI from '../contracts/ETCMC-DAO1-ABI.json';

const dao1Address = "0x1D606b6490510bBEbcADb256041dC50975C5E43D"; // DAO1 contract address
const dao2Address = "0xF88CBCE86c54d45B37aD514C7799d59Bb8C2Dd98"; // DAO2 contract address
const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b"; // DAO3 contract address
const tokenAddress = "0x6c3B413C461c42a88160Ed1B1B31d6f7b02a1C83"; // ETCPOW contract address

const getABI = (contractAddress) => {
  switch (contractAddress.toLowerCase()) {
    case dao3Address.toLowerCase():
      return DAO3_ABI;
    case dao2Address.toLowerCase():
      return DAO2_ABI;
    case dao1Address.toLowerCase():
      return DAO1_ABI;
    default:
      throw new Error("Unsupported contract address");
  }
};

const UpgradeSection = () => {
  const [nftContractAddress, setNftContractAddress] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [upgradedNFT, setUpgradedNFT] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({ visible: false, title: '', message: '', isLoading: false, showCloseButton: false });

  const showNotification = (title, message, isLoading = false, showCloseButton = false) => {
    setNotification({ visible: true, title, message, isLoading, showCloseButton });
  };

  const hideNotification = () => {
    setNotification({ visible: false, title: '', message: '', isLoading: false, showCloseButton: false });
  };

  const upgradeNFT = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new BrowserProvider(ethereum);
        const signer = await provider.getSigner();

        // Approve ETCPOW spending
        let transaction = await new Contract(tokenAddress, ETCPOW_ABI, signer).approve(dao3Address, parseEther("1"));
        showNotification('Approving ETCPOW...', 'Please wait while your ETCPOW is being approved.', true);
        await transaction.wait();
        hideNotification();
        showNotification('ETCPOW Approved!', 'ETCPOW successfully approved. Please sign the NFT Transfer transaction.', false, true);

        // Approve NFT contract to transfer NFT
        const nftABI = getABI(nftContractAddress);
        const nftContract = new Contract(nftContractAddress, nftABI, signer);
        transaction = await nftContract.approve(dao3Address, tokenId);
        showNotification('Approving NFT Transfer...', 'Please wait while your NFT transfer is being approved.', true);
        await transaction.wait();
        hideNotification();
        showNotification('NFT Transfer Approved!', 'NFT Transfer successfully approved. Please sign the Upgrade NFT transaction.', false, true);

        // Gas estimation code starts here
        console.log('Attempting to retrieve gas fee data...');
        const feeData = await provider.getFeeData();
        console.log('Fee data:', feeData);

        // Fallback to gasPrice if maxFeePerGas and maxPriorityFeePerGas are not available
        const gasSettings = feeData.maxFeePerGas && feeData.maxPriorityFeePerGas
          ? {
              maxFeePerGas: feeData.maxFeePerGas,
              maxPriorityFeePerGas: feeData.maxPriorityFeePerGas
            }
          : {
              gasPrice: feeData.gasPrice
            };

        // Add a 10% buffer to the gas price
        let gasLimit;
        if (gasSettings.gasPrice) {
          gasLimit = gasSettings.gasPrice * 130n / 100n;
        } else {
          gasLimit = feeData.maxFeePerGas * 130n / 100n;
        }
        console.log('Gas limit with buffer:', gasLimit.toString());

        // Upgrade the NFT using DAO3 contract
        const dao3Contract = new Contract(dao3Address, DAO3_ABI, signer);
        transaction = await dao3Contract.upgradeNFT(nftContractAddress, tokenId, {
          gasPrice: gasLimit,
        });

        showNotification('Upgrading NFT...', 'Please wait while your NFT is being upgraded.', true);
        const receipt = await transaction.wait();

        if (receipt.status === 1) { // Successful transaction
          hideNotification();
          showNotification('Upgrade Successful!', 'Fetching upgraded NFT data...', true);

          // Fetch upgraded NFT data
          const iface = new Interface(DAO3_ABI);
          let newTokenId;
          for (const log of receipt.logs) {
            try {
              const parsedLog = iface.parseLog(log);
              if (parsedLog.name === 'Upgrade') {
                newTokenId = parsedLog.args.newTokenId.toString();
                break;
              }
            } catch (err) {
              console.error('Failed to parse log:', err);
            }
          }

          if (newTokenId) {
            const tokenURI = await dao3Contract.tokenURI(newTokenId);
            const metadata = await fetch(tokenURI).then(response => response.json());

            const upgradedNFTData = {
              tokenId: newTokenId,
              name: metadata.name,
              imageUrl: metadata.image,
              contractAddress: dao3Address
            };

            setUpgradedNFT(upgradedNFTData);
            hideNotification();
            setShowModal(true); // Show the custom modal with the upgraded NFT
          } else {
            throw new Error('Failed to retrieve upgraded NFT data.');
          }
        } else {
          hideNotification();
          showNotification('Upgrade Failed', 'The NFT upgrade failed. Please try again.', false, true);
        }
      } else {
        showNotification('Error', 'Ethereum object not found!', false, true);
      }
    } catch (error) {
      console.error('Upgrade error:', error);
      hideNotification();
      showNotification('Upgrade Failed', 'The NFT upgrade failed. Please try again.', false, true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="upgrade-section">
      <select
        className="custom-select"
        value={nftContractAddress}
        onChange={(e) => setNftContractAddress(e.target.value)}
      >
        <option value="">Select Contract</option>
        <option value={dao1Address}>DAO1 Contract</option>
        <option value={dao2Address}>DAO2 Contract</option>
        <option value={dao3Address}>DAO3 Contract</option>
      </select>
      <input
        type="text"
        placeholder="Token ID"
        value={tokenId}
        onChange={(e) => setTokenId(e.target.value)}
        className="token-id-input custom-input"
      />
      <button className="custom-btn" onClick={upgradeNFT}>UPGRADE NFT</button>

      {notification.visible && (
        <Notification
          title={notification.title}
          message={notification.message}
          isLoading={notification.isLoading}
          onClose={hideNotification} // Allow closing the notification
          showCloseButton={notification.showCloseButton} // Show close button for failed transactions
        />
      )}

      {showModal && upgradedNFT && (
        <NFTModal
          nft={upgradedNFT}
          isFirstNFT={true}
          isLastNFT={true}
          isUpgrade={true}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default UpgradeSection;







