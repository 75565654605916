// src/components/Header.js
import React, { useState } from 'react';
import logo from '../images/ETCMC-LOGO1.png';
import WalletConnect from './WalletConnect';
import ViewNFTGallery from './ViewNFTGallery';
import NFTBreakdown from './NFTBreakdown'; // Import the new component

const Header = ({ setWalletConnected, setSigner, setContract, signer }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [showNFTBreakdown, setShowNFTBreakdown] = useState(false); // State to control NFT Breakdown visibility

  return (
    <header className="header">
      <div className="header-left">
        <img src={logo} alt="ETCMC Logo" />
        <WalletConnect 
          setWalletConnected={setWalletConnected} 
          setSigner={setSigner} 
          setContract={setContract}  // Pass setContract to WalletConnect
        />
      </div>
      <div className="header-right">
        <a href="https://etcmc-marketplace.netlify.app/marketplace" className="nav-link">MARKETPLACE</a>
        {/* 
        <a href="#" className="nav-link" onClick={() => setShowGallery(true)}>GALLERY</a>
        */}
        <a href="#" className="nav-link" onClick={() => setShowNFTBreakdown(true)}>NFT BREAKDOWN</a>
        {/* 
        <a href="#" className="nav-link">DAO BREAKDOWN</a>
        */}
      </div>
      {showGallery && <ViewNFTGallery signer={signer} onClose={() => setShowGallery(false)} />}
      {showNFTBreakdown && <NFTBreakdown onClose={() => setShowNFTBreakdown(false)} />} {/* Conditionally render the NFTBreakdown component */}
    </header>
  );
};

export default Header;









