import React from 'react';

const Notification = ({ title, message, isLoading, onClose, showCloseButton }) => {
  return (
    <div className="notification">
      <div className="notification-content">
        {isLoading && <div className="spinner"></div>}
        <h2>{title}</h2>
        <p>{message}</p>
        {showCloseButton && (
          <button className="close-button" onClick={onClose}>
            Close
          </button>
        )}
      </div>
    </div>
  );
};

export default Notification;

