import React, { useEffect, useState } from 'react';
import { formatEther } from 'ethers';
import '../styles.css';

const UpgradeFees = ({ contract }) => {
  const [fees, setFees] = useState([]);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const fetchUpgradeFees = async () => {
      if (contract) {
        try {
          const [tiers, costs] = await contract.AllUpgradeCosts(); // Fetch the tier numbers and costs

          // Define tier names corresponding to the tier numbers
          const tierNames = {
            1: "OPAL",
            2: "ORANGE",
            3: "BRONZE",
            4: "PINK",
            5: "CRYSTAL",
            6: "SILVER",
            7: "YELLOW",
            8: "RED",
            9: "GOLD",
            10: "TEAL",
            11: "PURPLE",
            12: "BLUE",
            13: "GREY",
            14: "BLACK"
          };

          // Map each tier number to its name and cost
          const formattedFees = tiers.map((tier, index) => ({
            name: tierNames[tier],
            cost: formatEther(costs[index].toString()) // Convert BigInt cost to string and format to ETC
          }));

          setFees(formattedFees); // Set the fees state with formatted fees
        } catch (error) {
          console.error('Failed to fetch upgrade fees:', error);
        }
      }
    };

    fetchUpgradeFees();
  }, [contract]);

  if (!isVisible) return null;

  return (
    <div className="upgrade-fees">
      <button className="close-btn" onClick={() => setIsVisible(false)}>X</button>
      <h2>Upgrade Fees</h2>
      {fees.map((fee, index) => (
        <p key={index}>{fee.name}: {fee.cost} ETCPOW</p>
      ))}
    </div>
  );
};

export default UpgradeFees;






