import React from 'react';
import '../styles.css';  // Import global styles
import DiscordIcon from '../images/DISCORD.png';  // Import the Discord icon
import TelegramIcon from '../images/TELEGRAM.png';  // Import the Telegram icon
import TwitterIcon from '../images/x.png';  // Import the Twitter icon
import YoutubeIcon from '../images/YOUTUBE.png';  
import ETCMCICON from '../images/ETCMC.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        &copy; ETCMC 2024
      </div>
      <div className="footer-center">
        <a href="https://discord.gg/etcmc" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={DiscordIcon} alt="Discord" className="social-icon" />
        </a>
        <a href="https://t.me/+tZVSbPGAaWM1YTFk" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={TelegramIcon} alt="Telegram" className="social-icon" />
        </a>
        <a href="https://x.com/ETCMC777" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={TwitterIcon} alt="Twitter" className="social-icon" />
        </a>
        <a href="https://youtube.com/@etcmc-officialstudio.?si=b7y9xCUzLXC1h1VC" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={YoutubeIcon} alt="Youtube" className="social-icon" />
        </a>
        <a href="https://etcmc.org/" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src={ETCMCICON} alt="ETCMC" className="social-icon" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;




