import React, { useState, useEffect } from 'react';
import { BrowserProvider, Contract, parseEther, Interface } from 'ethers';
import NFTModal from './NFTModal';
import Notification from './Notification';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b"; // DAO3 contract address

const MintSection = () => {
  const [numberToMint, setNumberToMint] = useState(1);
  const [minting, setMinting] = useState(false);
  const [mintedNFTs, setMintedNFTs] = useState([]);
  const [currentNFTIndex, setCurrentNFTIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({ visible: false, title: '', message: '', isLoading: false, showCloseButton: false });
  const [mintPrice, setMintPrice] = useState(null);

  // Fetch the mint price from the contract
  useEffect(() => {
    const fetchMintPrice = async () => {
      try {
        const { ethereum } = window;
        if (ethereum) {
          const provider = new BrowserProvider(ethereum);
          const signer = await provider.getSigner();
          const contract = new Contract(dao3Address, DAO3_ABI, signer);
          const price = await contract.mintPrice(); // Fetch mint price
          setMintPrice(price); // Store mint price in state
        }
      } catch (error) {
        console.error('Error fetching mint price:', error);
      }
    };
    fetchMintPrice();
  }, []);

  const showNotification = (title, message, isLoading = false, showCloseButton = false) => {
    setNotification({ visible: true, title, message, isLoading, showCloseButton });
  };

  const hideNotification = () => {
    setNotification({ visible: false, title: '', message: '', isLoading: false, showCloseButton: false });
  };

  const mintNFTs = async () => {
    if (minting) return; // Prevent multiple clicks

    setMinting(true);

    try {
      const { ethereum } = window;

      if (ethereum) {
        console.log('Ethereum object found');
        const provider = new BrowserProvider(ethereum);
        const signer = await provider.getSigner();
        console.log('Signer:', signer);

        const contract = new Contract(dao3Address, DAO3_ABI, signer);
        console.log('Contract instance created:', contract);

        const value = parseEther((numberToMint * parseFloat(mintPrice) / 1e18).toString()); // Use fetched mint price
        console.log('Value for minting:', value.toString());

        console.log('Attempting to retrieve gas fee data...');
        const feeData = await provider.getFeeData();
        console.log('Fee data:', feeData);

        // Fallback to gasPrice if maxFeePerGas and maxPriorityFeePerGas are not available
        const gasSettings = feeData.maxFeePerGas && feeData.maxPriorityFeePerGas
          ? {
              maxFeePerGas: feeData.maxFeePerGas,
              maxPriorityFeePerGas: feeData.maxPriorityFeePerGas
            }
          : {
              gasPrice: feeData.gasPrice
            };

        
        let gasLimit;
        if (gasSettings.gasPrice) {
          gasLimit = gasSettings.gasPrice * 130n / 100n;
        } else {
          gasLimit = feeData.maxFeePerGas * 130n / 100n;
        }
        console.log('Gas limit with buffer:', gasLimit.toString());

        const transaction = await contract.mint(numberToMint, {
          value,
          gasPrice: gasLimit
        });
        console.log('Transaction sent:', transaction);

        showNotification('Minting NFT...', 'Please wait while your NFTs are being minted.', true);

        const receipt = await transaction.wait();
        console.log('Transaction receipt:', receipt);

        if (receipt.status === 1) { // Successful transaction
          hideNotification();
          showNotification('Mint Successful!', `You have successfully minted ${numberToMint} NFT(s). Fetching NFT data...`, true);

          // Fetch NFT data
          const mintedNFTs = await extractMintedNFTs(receipt, contract);
          setMintedNFTs(mintedNFTs);
          setCurrentNFTIndex(0);
          hideNotification();

          if (mintedNFTs.length > 0) {
            setShowModal(true); // Show the custom modal with NFTs
          } else {
            showNotification('Success', 'NFTs minted successfully, but no NFT data was found.', false, true);
          }
        } else {
          hideNotification();
          showNotification('Transaction Failed', 'Please manually raise the gas limit and try again.', false, true);
        }
      } else {
        console.log('Ethereum object not found');
        showNotification('Error', 'Ethereum object not found!', false, true);
      }
    } catch (error) {
      console.error('Error in mintNFTs function:', error);
      hideNotification();
      showNotification('Transaction Failed', error.message || 'Please manually raise the gas limit and try again.', false, true);
    } finally {
      setMinting(false);
    }
  };

  const extractMintedNFTs = async (receipt, contract) => {
    const mintedNFTs = [];
    const iface = new Interface(DAO3_ABI);  // Correct Interface usage

    // Use logs to decode the Mint events
    for (const log of receipt.logs) {
      try {
        const parsedLog = iface.parseLog(log);
        if (parsedLog.name === 'Mint') {
          const tokenId = parsedLog.args.tokenId.toString();
          const tokenURI = await contract.tokenURI(tokenId);
          const metadata = await fetch(tokenURI).then(response => response.json());

          mintedNFTs.push({
            tokenId,
            name: metadata.name,
            imageUrl: metadata.image,
            contractAddress: dao3Address
          });
        }
      } catch (err) {
        console.error('Failed to parse log:', err);
      }
    }

    return mintedNFTs;
  };

  const handleNext = () => {
    if (currentNFTIndex < mintedNFTs.length - 1) {
      setCurrentNFTIndex(currentNFTIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentNFTIndex > 0) {
      setCurrentNFTIndex(currentNFTIndex - 1);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="mint-section">
      <input
        type="number"
        min="1"
        value={numberToMint}
        onChange={(e) => setNumberToMint(e.target.value)}
        placeholder="Number of NFTs"
      />
      <button className="custom-btn" onClick={mintNFTs} disabled={minting || !mintPrice}>
        {minting ? 'Minting...' : `MINT (${parseFloat(mintPrice) / 1e18} ETC)`} 
      </button>

      {notification.visible && (
        <Notification
          title={notification.title}
          message={notification.message}
          isLoading={notification.isLoading}
          onClose={hideNotification}
          showCloseButton={notification.showCloseButton}
        />
      )}

      {showModal && (
        <NFTModal
          nft={mintedNFTs[currentNFTIndex]}
          isFirstNFT={currentNFTIndex === 0}
          isLastNFT={currentNFTIndex === mintedNFTs.length - 1}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default MintSection;








































