import React, { useEffect, useState } from 'react';
import { formatEther } from 'ethers'; // Import formatEther from ethers
import '../styles.css';  // Import the CSS file for styling

const TotalMints = ({ contract }) => {
  const [mintPrice, setMintPrice] = useState(null);
  const [totalMinted, setTotalMinted] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const fetchMintData = async () => {
      if (contract) {
        try {
          const price = await contract.mintPrice();
          const total = await contract.totalMinted();
          setMintPrice(formatEther(price)); // Convert price from wei to ETC
          setTotalMinted(total.toString());
        } catch (error) {
          console.error('Failed to fetch mint data:', error);
        }
      }
    };

    fetchMintData();
  }, [contract]);

  if (!isVisible) return null;

  return (
    <div className="total-mints">
      <button className="close-btn" onClick={() => setIsVisible(false)}>X</button>
      <h2>Total Mints</h2>
      <p>Mint Price: {mintPrice} ETC</p>
      <p>Total Minted: {totalMinted}</p>
    </div>
  );
};

export default TotalMints;


