// src/components/NFTGallery.js

import React, { useEffect } from 'react';
import '../styles.css';  // Ensure your styles are applied

// Import images from src/images
import opalImg from '../images/OPAL.jpg';
import orangeImg from '../images/ORANGE.jpg';
import bronzeImg from '../images/BRONZE.jpg';
import pinkImg from '../images/PINK.jpg';
import crystalImg from '../images/CRYSTAL.jpg';
import silverImg from '../images/SILVER.jpg';
import yellowImg from '../images/YELLOW.jpg';
import redImg from '../images/RED.jpg';
import goldImg from '../images/GOLD.jpg';
import tealImg from '../images/TEAL.jpg';
import purpleImg from '../images/PURPLE.jpg';
import blueImg from '../images/BLUE.jpg';
import greyImg from '../images/GREY.jpg';
import blackImg from '../images/BLACK.jpg';
import greenImg from '../images/GREEN.jpg';
import etcpowImg from '../images/ETCPOW.jpg';
import doubleImg from '../images/DOUBLE.jpg';

const NFTGallery = () => {
  const nftImages = [
    etcpowImg,
    doubleImg,
    opalImg,
    orangeImg,
    bronzeImg,
    pinkImg,
    crystalImg,
    silverImg,
    yellowImg,
    redImg,
    goldImg,
    tealImg,
    purpleImg,
    blueImg,
    greyImg,
    blackImg,
    greenImg,
  ];

  useEffect(() => {
    const gallery = document.getElementById('nft-gallery');
    let currentIndex = 0;

    const scrollToNextImage = () => {
      currentIndex = (currentIndex + 1) % nftImages.length;
      gallery.scrollTo({
        left: gallery.clientWidth * currentIndex,
        behavior: 'smooth',
      });
    };

    const interval = setInterval(scrollToNextImage, 2000);  // 2 seconds per image

    return () => clearInterval(interval);  // Cleanup on component unmount
  }, [nftImages]);

  return (
    <section className="gallery-container">
      <div className="gallery-title">
        <p>RANDOM MINT</p>
      </div>
      <div id="nft-gallery" className="custom-gallery">
        {nftImages.map((src, index) => (
          <img key={index} src={src} alt={`NFT ${index + 1}`} className="gallery-image" />
        ))}
      </div>
    </section>
  );
};

export default NFTGallery;

















