import React from 'react';
import '../styles.css'; 
import nftBreakdownImage from '../images/NFT-BRAKEDOWN.png'; // Ensure the path is correct

const NFTBreakdown = ({ onClose }) => {
  return (
    <div className="nft-breakdown">
      <button className="close-btn" onClick={onClose}>X</button>
      <img src={nftBreakdownImage} alt="NFT Breakdown" className="nft-breakdown-image" />
    </div>
  );
};

export default NFTBreakdown;

