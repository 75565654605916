import React, { useState, useEffect } from 'react';

const TimeComponent = ({ setSiteUnlocked }) => {
  const [serverTime, setServerTime] = useState(null);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const fetchTime = async () => {
      try {
        const response = await fetch('https://worldtimeapi.org/api/timezone/Europe/London'); // Fetch time for BST
        const data = await response.json();
        const currentTime = new Date(data.datetime); // Use the London time in ISO format

        // Define the unlock time in BST: 2 PM BST on 7th September 2024
        const unlockTime = new Date('2024-09-07T13:00:00Z'); // This is 2 PM BST (1 PM UTC)

        setServerTime(currentTime);

        // Calculate the time difference
        const timeDifference = unlockTime - currentTime;
        if (timeDifference > 0) {
          setTimeRemaining(timeDifference);
          setIsUnlocked(false);
        } else {
          setIsUnlocked(true);
          setSiteUnlocked(true); // Notify parent that the site is unlocked
        }

        // Countdown interval
        const interval = setInterval(() => {
          const newCurrentTime = new Date(new Date().toUTCString());
          const newTimeDifference = unlockTime - newCurrentTime;
          if (newTimeDifference <= 0) {
            setIsUnlocked(true);
            setSiteUnlocked(true); // Notify parent that the site is unlocked
            clearInterval(interval);
          } else {
            setTimeRemaining(newTimeDifference);
          }
        }, 1000);

        return () => clearInterval(interval); // Cleanup on unmount
      } catch (error) {
        console.error('Error fetching time:', error);
      }
    };

    fetchTime();
  }, [setSiteUnlocked]);

  // Function to format time remaining
  const formatTimeRemaining = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="centered-container">
      {serverTime ? (
        isUnlocked ? (
          <h2>The site is now unlocked!</h2>
        ) : (
          <div>
            <h2>The site will unlock at 2 PM BST on 7th September 2024</h2>
            {timeRemaining && (
              <p>Time remaining: {formatTimeRemaining(timeRemaining)}</p>
            )}
          </div>
        )
      ) : (
        <p>Loading current time...</p>
      )}
    </div>
  );
};

export default TimeComponent;





