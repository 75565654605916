import React, { useState } from 'react';
import { BrowserProvider, Contract, Interface } from 'ethers';
import NFTModal from './NFTModal';
import Notification from './Notification';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b"; // DAO3 contract address

const DoubleMintSection = ({ signer }) => {
  const [contractAddress, setContractAddress] = useState(dao3Address); // Default to DAO3
  const [tokenId, setTokenId] = useState('');
  const [minting, setMinting] = useState(false);
  const [mintedNFTs, setMintedNFTs] = useState([]);
  const [currentNFTIndex, setCurrentNFTIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState({
    visible: false,
    title: '',
    message: '',
    isLoading: false,
    showCloseButton: false,
  });

  const showNotification = (title, message, isLoading = false, showCloseButton = false) => {
    setNotification({ visible: true, title, message, isLoading, showCloseButton });
  };

  const hideNotification = () => {
    setNotification({ visible: false, title: '', message: '', isLoading: false, showCloseButton: false });
  };

  const extractMintedNFTs = async (receipt, contract) => {
    const mintedNFTs = [];
    const iface = new Interface(DAO3_ABI);

    for (const log of receipt.logs) {
      try {
        const parsedLog = iface.parseLog(log);
        if (parsedLog.name === 'Transfer') {
          const tokenId = parsedLog.args.tokenId.toString();
          const tokenURI = await contract.tokenURI(tokenId);
          const metadata = await fetch(tokenURI).then(response => response.json());

          mintedNFTs.push({
            tokenId,
            name: metadata.name,
            imageUrl: metadata.image,
            contractAddress: dao3Address,
          });
        }
      } catch (err) {
        console.error('Failed to parse log:', err);
      }
    }

    return mintedNFTs;
  };

  const claimDoubleMint = async () => {
    if (minting) return; // Prevent multiple clicks

    setMinting(true);

    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new BrowserProvider(ethereum);
        const nftContract = new Contract(contractAddress, DAO3_ABI, signer);

        // Approve DAO3 contract to spend Tier 17 NFT
        let transaction = await nftContract.approve(dao3Address, tokenId);
        showNotification('Approving NFT Transfer...', 'Please wait while your NFT transfer is being approved.', true);
        await transaction.wait();
        
        hideNotification();
        showNotification('Approval Successful', 'NFT Transfer approved. Please sign the mint transaction.', false, true);

        // Gas estimation code starts here
        console.log('Attempting to retrieve gas fee data...');
        const feeData = await provider.getFeeData();
        console.log('Fee data:', feeData);

        // Fallback to gasPrice if maxFeePerGas and maxPriorityFeePerGas are not available
        const gasSettings = feeData.maxFeePerGas && feeData.maxPriorityFeePerGas
          ? {
              maxFeePerGas: feeData.maxFeePerGas,
              maxPriorityFeePerGas: feeData.maxPriorityFeePerGas
            }
          : {
              gasPrice: feeData.gasPrice
            };

        // Add a 10% buffer to the gas price
        let gasLimit;
        if (gasSettings.gasPrice) {
          gasLimit = gasSettings.gasPrice * 130n / 100n;
        } else {
          gasLimit = feeData.maxFeePerGas * 130n / 100n;
        }
        console.log('Gas limit with buffer:', gasLimit.toString());

        // Claim Double Mint
        transaction = await nftContract.claimDoubleMint(tokenId, {
          gasPrice: gasLimit
        });
        showNotification('Minting NFTs...', 'Please wait while your NFTs are being minted.', true);
        const receipt = await transaction.wait();

        if (receipt.status === 1) {
          hideNotification();
          showNotification('Mint Successful!', `You have successfully claimed double mint. Fetching NFT data...`, true);

          // Fetch NFT data
          const mintedNFTs = await extractMintedNFTs(receipt, nftContract);
          setMintedNFTs(mintedNFTs);
          setCurrentNFTIndex(0);
          hideNotification();

          if (mintedNFTs.length > 0) {
            setShowModal(true); // Show the custom modal with NFTs
          } else {
            showNotification('Success', 'NFTs minted successfully, but no NFT data was found.', false, true);
          }
        } else {
          hideNotification();
          showNotification('Transaction Failed', 'Please manually raise the gas limit and try again.', false, true);
        }
      } else {
        showNotification('Error', 'Ethereum object not found!', false, true);
      }
    } catch (error) {
      console.error('Minting error:', error);
      hideNotification();
      showNotification('Transaction Failed', 'Please manually raise the gas limit and try again.', false, true);
    } finally {
      setMinting(false);
    }
  };

  const handleNext = () => {
    if (currentNFTIndex < mintedNFTs.length - 1) {
      setCurrentNFTIndex(currentNFTIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentNFTIndex > 0) {
      setCurrentNFTIndex(currentNFTIndex - 1);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDropdownChange = (e) => {
    setContractAddress(e.target.value);
  };

  return (
    <div className="double-mint-section">
      <div className="input-with-dropdown">
        <select
          onChange={handleDropdownChange}
          className="custom-select"
        >
          <option value="">Select Contract</option>
          <option value={dao3Address}>DAO3 Contract</option>
        </select>
      </div>
      <input
        type="text"
        placeholder="Token ID"
        value={tokenId}
        onChange={(e) => setTokenId(e.target.value)}
        className="custom-input"
      />
      <button className="custom-btn" onClick={claimDoubleMint} disabled={minting}>
        {minting ? 'Minting...' : 'CLAIM DOUBLE MINT'}
      </button>

      {notification.visible && (
        <Notification
          title={notification.title}
          message={notification.message}
          isLoading={notification.isLoading}
          onClose={hideNotification} // Allow closing the notification
          showCloseButton={notification.showCloseButton} // Show close button for failed transactions
        />
      )}

      {showModal && (
        <NFTModal
          nft={mintedNFTs[currentNFTIndex]}
          isFirstNFT={currentNFTIndex === 0}
          isLastNFT={currentNFTIndex === mintedNFTs.length - 1}
          onNext={handleNext}
          onBack={handleBack}
          onClose={handleClose}
        />
      )}
    </div>
  );
};

export default DoubleMintSection;











