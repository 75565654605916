import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Header from './components/Header';
import Footer from './components/Footer';
import NFTGallery from './components/NFTGallery';
import MintSection from './components/MintSection';
import UpgradeSection from './components/UpgradeSection';
import DoubleMintSection from './components/DoubleMintSection';
import TotalMints from './components/TotalMints';
import UpgradeFees from './components/UpgradeFees';
import TimeComponent from './components/TimeComponent'; // Import TimeComponent
import './styles.css';
import FIRE3 from './images/FIRE3.png';
import FIRE4 from './images/FIRE4.png';
import DAO3_ABI from './contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";
const adminAddresses = ["0x19c6A0A24E418E5Ab385BEC754ab40EC295df3FF", "0x64A03f51c201C05E7FF4F02AfddA2BB14540e34D"];

const App = () => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);
  const [siteUnlocked, setSiteUnlocked] = useState(false); // Track if the site is unlocked
  const [isAdmin, setIsAdmin] = useState(false); // Track if the connected wallet is an admin

  useEffect(() => {
    const initializeContract = async () => {
      if (walletConnected && signer) {
        try {
          const contractInstance = new ethers.Contract(dao3Address, DAO3_ABI, signer);
          setContract(contractInstance);
        } catch (error) {
          console.error('Contract initialization failed:', error);
        }
      }
    };

    initializeContract();
  }, [walletConnected, signer]);

  // Function to check if the wallet is an admin
  const handleAdminUnlock = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert('MetaMask is not installed. Please install MetaMask and try again.');
        return;
      }

      // Request wallet connection
      const provider = new ethers.BrowserProvider(ethereum);
      await provider.send("eth_requestAccounts", []); // Request wallet connection
      const signer = await provider.getSigner();
      setSigner(signer);
      setWalletConnected(true); // Mark the wallet as connected

      // Check if the wallet address is an admin
      const address = await signer.getAddress();
      if (adminAddresses.includes(address)) {
        setIsAdmin(true);
        setSiteUnlocked(true); // Unlock the site if the wallet is an admin
      } else {
        alert('You are not authorized to unlock the site.');
      }
    } catch (error) {
      console.error("Error during wallet connection or admin check:", error);
      alert('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="App">
      {!siteUnlocked ? (
        <div className="centered-container">
          <TimeComponent setSiteUnlocked={setSiteUnlocked} />
          <button onClick={handleAdminUnlock} className="admin-unlock-btn">
            Admin
          </button>
        </div>
      ) : (
        <>
          <Header
            setWalletConnected={setWalletConnected}
            setSigner={setSigner}
            setContract={setContract}
            signer={signer}
          />
          <div className="app-title">
            <h1>ETCMC DAO3-FURNACE</h1>
          </div>
          {walletConnected ? (
            <div className="container">
              <NFTGallery signer={signer} />
              <div className="action-buttons">
                <UpgradeSection signer={signer} />
                <MintSection signer={signer} contract={contract} />
                <DoubleMintSection signer={signer} />
              </div>
              <TotalMints contract={contract} />
              <UpgradeFees contract={contract} />
            </div>
          ) : (
            <p>Please connect your wallet to view and interact with NFTs.</p>
          )}
          <Footer />
          <img src={FIRE3} alt="Fire" className="fire-image" />
          <img src={FIRE4} alt="Flipped Fire" className="flipped-fire-image" />
        </>
      )}
    </div>
  );
};

export default App;























