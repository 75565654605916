import React, { useEffect, useState } from 'react';
import FIREWORK from '../images/FIREWORK.gif';  // Import the fireworks GIF

const NFTModal = ({ nft, isFirstNFT, isLastNFT, onNext, onBack, onClose, isUpgrade }) => {
  const [showFireworks, setShowFireworks] = useState(false);

  useEffect(() => {
    if (nft) {
      // Show fireworks when the NFT is displayed
      setShowFireworks(true);
      
      // Hide the fireworks after 3 seconds
      const timer = setTimeout(() => {
        setShowFireworks(false);
      }, 3000);

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [nft]);

  if (!nft) return null;

  return (
    <div className="nft-modal">
      {showFireworks && (
        <div className="firework-effect">
          <img src={FIREWORK} alt="Fireworks" />
        </div>
      )}
      <div className="nft-modal-content">
        {isUpgrade && <h2>You have upgraded to a</h2>}
        <h2>{nft.name}</h2>
        <img src={nft.imageUrl} alt={nft.name} style={{ width: '100%', height: 'auto' }} />
        <p><strong>Token ID:</strong> {nft.tokenId}</p>
        <p><strong>Contract Address:</strong> {nft.contractAddress}</p>
        <div className="nft-modal-buttons">
          {!isFirstNFT && <button onClick={onBack}>Back</button>}
          {!isLastNFT && <button onClick={onNext}>Next</button>}
          {isLastNFT && <button onClick={onClose}>Close</button>}
        </div>
      </div>
    </div>
  );
};

export default NFTModal;


