import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import DAO3_ABI from '../contracts/ETCMC-DAO3-ABI.json'; // Import your DAO3 contract ABI

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";

const ViewNFTGallery = ({ signer, onClose }) => {
    const [nfts, setNfts] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch the metadata from the token URI
    const fetchMetadata = async (tokenURI) => {
        try {
            const response = await fetch(tokenURI);
            const metadata = await response.json();
            return metadata.image; // Assuming the image URL is stored under the 'image' key
        } catch (error) {
            console.error("Failed to fetch metadata:", error);
            return null;
        }
    };

    // Fetch the NFTs owned by the user
    const fetchNFTs = async (userAddress) => {
        try {
            const contract = new ethers.Contract(dao3Address, DAO3_ABI, signer);
            const balance = await contract.balanceOf(userAddress); // Number of NFTs the user owns
            const nftPromises = [];

            for (let i = 0; i < balance; i++) {
                const tokenId = await contract.tokenOfOwnerByIndex(userAddress, i); // Get the token ID owned by the user at index i
                const tokenURI = await contract.tokenURI(tokenId); // Get the token URI for the token ID
                const imageUrl = await fetchMetadata(tokenURI); // Fetch the image URL from the metadata

                nftPromises.push({
                    tokenId,
                    imageUrl,
                });
            }

            const nftData = await Promise.all(nftPromises);

            // Map the data into the format your gallery expects
            const formattedNFTs = nftData.map(nft => ({
                id: nft.tokenId.toString(),
                image_url: nft.imageUrl,
                name: `Token ID: ${nft.tokenId}`
            }));

            return formattedNFTs;
        } catch (error) {
            console.error("Failed to fetch NFTs from contract:", error);
            return [];
        }
    };

    useEffect(() => {
        if (signer) {
            const initialFetch = async () => {
                setLoading(true);
                try {
                    const userAddress = await signer.getAddress();
                    console.log("User Address:", userAddress);

                    const fetchedNFTs = await fetchNFTs(userAddress);
                    console.log("NFTs fetched from contract:", fetchedNFTs);
                    setNfts(fetchedNFTs);
                } catch (error) {
                    console.error("Failed to fetch NFTs:", error);
                } finally {
                    setLoading(false);
                }
            };

            initialFetch();
        }
    }, [signer]);

    if (!signer) return null;

    return (
        <div className="nft-gallery-modal">
            <div className="nft-gallery-modal-content">
                <button className="close-btn" onClick={onClose}>X</button>
                <h2>Your NFT Gallery</h2>
                {loading ? (
                    <p>Loading NFTs...</p>
                ) : (
                    <div className="nft-gallery-grid">
                        {nfts.map((nft, index) => (
                            <div key={index} className="nft-item">
                                <img src={nft.image_url} alt={nft.name || `Token ID: ${nft.id}`} />
                                <h3>{nft.name || `Token ID: ${nft.id}`}</h3>
                                <p><strong>Token ID:</strong> {nft.id}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewNFTGallery;






























